export default [
  {
    path: '/med-encyclopedia',
    name: 'med-encyclopedia',
    component: () => import(/* webpackChunkName: "MedEncyclopediaList" */'@/views/med-encyclopedia/List.vue'),
    meta: {
      pageTitle: 'Мед. Энциклопедия',
      requiresAuth: true,
      navActiveLink: 'med-encyclopedia',
      breadcrumb: [
        {
          text: 'Мед. Энциклопедия',
          active: true,
        },
      ],
    },
  },
  {
    path: '/med-encyclopedia/create',
    name: 'med-encyclopedia-create',
    component: () => import(/* webpackChunkName: "MedEncyclopediaCreate" */'@/views/med-encyclopedia/Create.vue'),
    meta: {
      pageTitle: 'Создание',
      requiresAuth: true,
      navActiveLink: 'med-encyclopedia',
      breadcrumb: [
        {
          text: 'Мед. Энциклопедия',
          active: false,
          to: {
            name: 'med-encyclopedia',
          },
        },
        {
          text: 'Создание страницы мед. энциклопедии',
          active: true,
        },
      ],
    },
  },
  {
    path: '/med-encyclopedia/update/:id',
    name: 'med-encyclopedia-update',
    component: () => import(/* webpackChunkName: "MedEncyclopediaUpdate" */'@/views/med-encyclopedia/Update.vue'),
    meta: {
      pageTitle: 'Редактирование страницы мед журнала',
      requiresAuth: true,
      navActiveLink: 'med-encyclopedia',
      breadcrumb: [
        {
          text: 'Мед. Энциклопедия',
          active: false,
          to: {
            name: 'med-encyclopedia',
          },
        },
        {
          text: 'Редактирование страницы мед. энциклопедии',
          active: true,
        },
      ],
    },
  },
]
