export default [
  {
    path: '/doctors-specialities/',
    name: 'doctors-specialities',
    component: () => import(/* webpackChunkName: "DoctorsSpecialitiesList" */'@/views/doctors-specialities/List.vue'),
    meta: {
      pageTitle: 'Специальности докторов',
      requiresAuth: true,
      navActiveLink: 'doctors-specialities',
      breadcrumb: [
        {
          text: 'Специальности докторов',
          active: true,
        },
      ],
    },
  },
  {
    path: '/doctors-specialities/create',
    name: 'doctors-specialities-create',
    component: () => import(/* webpackChunkName: "DoctorsSpecialitiesCreate" */'@/views/doctors-specialities/Create.vue'),
    meta: {
      pageTitle: 'Создание',
      requiresAuth: true,
      navActiveLink: 'doctors-specialities-create',
      breadcrumb: [
        {
          text: 'Специальности докторов',
          active: false,
          to: {
            name: 'doctors-specialities',
          },
        },
        {
          text: 'Создание специальности докторов',
          active: true,
        },
      ],
    },
  },
  {
    path: '/doctors-specialities/update/:id',
    name: 'doctors-specialities-update',
    component: () => import(/* webpackChunkName: "DoctorsSpecialitiesUpdate" */'@/views/doctors-specialities/Update.vue'),
    meta: {
      pageTitle: 'Редактирование специальности докторов',
      requiresAuth: true,
      navActiveLink: 'doctors-specialities-update',
      breadcrumb: [
        {
          text: 'Специальности докторов',
          active: false,
          to: {
            name: 'doctors-specialities',
          },
        },
        {
          text: 'Редактирование специальности докторов',
          active: true,
        },
      ],
    },
  },
]
