export default [
  {
    path: '/clinic-networks/',
    name: 'clinic-networks',
    component: () => import(/* webpackChunkName: "ClinicNetworksList" */'@/views/clinic-networks/List.vue'),
    meta: {
      pageTitle: 'Сеть клиник',
      requiresAuth: true,
      navActiveLink: 'clinic-networks',
      breadcrumb: [
        {
          text: 'Сеть клиник',
          active: true,
        },
      ],
    },
  },
  {
    path: '/clinic-networks/create',
    name: 'clinic-networks-create',
    component: () => import(/* webpackChunkName: "ClinicNetworksCreate" */'@/views/clinic-networks/Create.vue'),
    meta: {
      pageTitle: 'Создание',
      requiresAuth: true,
      navActiveLink: 'clinic-networks-create',
      breadcrumb: [
        {
          text: 'Сеть клиник',
          active: false,
          to: {
            name: 'clinic-networks',
          },
        },
        {
          text: 'Создание сети клиник',
          active: true,
        },
      ],
    },
  },
  {
    path: '/clinic-networks/update/:id',
    name: 'clinic-networks-update',
    component: () => import(/* webpackChunkName: "ClinicNetworksUpdate" */'@/views/clinic-networks/Update.vue'),
    meta: {
      pageTitle: 'Редактирование сети клиник',
      requiresAuth: true,
      navActiveLink: 'clinic-networks-update',
      breadcrumb: [
        {
          text: 'Сеть клиник',
          active: false,
          to: {
            name: 'clinic-networks',
          },
        },
        {
          text: 'Редактирование сети клиник',
          active: true,
        },
      ],
    },
  },
]
