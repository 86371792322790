// import store from '@/store'

export default [
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/users/List.vue'),
    meta: {
      pageTitle: 'Список пользователей',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Список пользователей',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/create',
    name: 'users-create',
    component: () => import('@/views/users/Create.vue'),
    props: true,
    meta: {
      pageTitle: 'Создание пользователя',
      requiresAuth: true,
      navActiveLink: 'users',
      breadcrumb: [
        {
          text: 'Список пользователей',
          active: false,
          to: '/users',
        },
        {
          text: 'Создание',
          active: true,
        },
      ],
    },
  },

  {
    path: '/users/update/:id',
    name: 'users-update',
    component: () => import('@/views/users/Update.vue'),
    props: true,
    meta: {
      pageTitle: 'Редактирование пользователя',
      requiresAuth: true,
      navActiveLink: 'users',
      breadcrumb: [
        {
          text: 'Список пользователей',
          active: false,
          to: '/users',
        },
        {
          text: 'Редактирование',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/categories/parent/:id',
  //   name: 'categories-parent',
  //   component: () => import('@/views/categories/List.vue'),
  //   props: true,
  //   meta: {
  //     pageTitle: 'Список категорий',
  //     requiresAuth: true,
  //     breadcrumb: [
  //       {
  //         text: 'Список категорий',
  //         active: false,
  //         to: '/categories',
  //       },
  //       {
  //         text: 'Категории родителя',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/categories/create',
  //   name: 'categories-create',
  //   component: () => import('@/views/categories/Create.vue'),
  //   props: true,
  //   meta: {
  //     pageTitle: 'Создание категории',
  //     requiresAuth: true,
  //     breadcrumb: [
  //       {
  //         text: 'Список категорий',
  //         active: false,
  //         to: '/categories',
  //       },
  //       {
  //         text: 'Создание',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/categories/update/:id',
  //   name: 'categories-update',
  //   component: () => import('@/views/categories/Update.vue'),
  //   props: true,
  //   meta: {
  //     pageTitle: 'Редактирование категории',
  //     requiresAuth: true,
  //     breadcrumb: [
  //       {
  //         text: 'Список категорий',
  //         active: false,
  //         to: '/categories',
  //       },
  //       {
  //         text: 'Редактирование',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
]
