import Vue from 'vue'
import VueRouter from 'vue-router'
import { isUserLoggedIn } from '@/auth/utils'
import Homepage from '@/router/pages/home'
import Users from '@/router/pages/users'
import MedJournalPages from '@/router/pages/medJournalPages'
import MedEncyclopediaPages from '@/router/pages/medEncyclopediaPages'
import Regions from '@/router/pages/regions'
import Cities from '@/router/pages/cities'
import Doctors from '@/router/pages/doctors'
import DoctorsReviews from '@/router/pages/doctorsReviews'
import DoctorsSpecialities from '@/router/pages/doctorsSpecialities'
import Clinics from '@/router/pages/clinics'
import ClinicsReviews from '@/router/pages/clinicsReviews'
import ClinicNetworks from '@/router/pages/clinicNetworks'
import ClinicTypes from '@/router/pages/clinicTypes'

import Metro from '@/router/pages/metro'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => console.log(err))
}

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...Homepage,
    ...Users,
    ...MedJournalPages,
    ...MedEncyclopediaPages,
    ...Regions,
    ...Cities,
    ...Doctors,
    ...DoctorsReviews,
    ...DoctorsSpecialities,
    ...Clinics,
    ...ClinicsReviews,
    ...ClinicNetworks,
    ...ClinicTypes,
    ...Metro,
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  // to, from, next
  const loggedIn = isUserLoggedIn()
  if (!loggedIn && to.matched.some(record => record.meta.requiresAuth)) {
    next({ name: 'login' })
  } else {
    next()
  }
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
