<template>
  <div>
    <small>
      Вставить:
      <span
        v-for="(suggestion, index) in suggestions"
        :key="index"
        style="margin-right:10px;"
      >
        <a
          href="javascript:void(0)"
          @click="insertSomething(suggestion)"
        >{{ suggestion }}</a>
      </span>
    </small>
  </div>
</template>

<script>
export default {
  props: {
    suggestions: {
      type: Array,
      default() {
        return ['«»', '«', '»', '–']
      },
    },
    prependSuggestion: {
      type: String,
      default() {
        return ''
      },
    },
    inputRef: {
      type: Object,
      default() {
        return {}
      },
    },
    inputRefName: {
      type: String,
      default() {
        return ''
      },
    },
    rewriting: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    insertSomething(value) {
      const valueString = this.prependSuggestion + value
      const field = this.inputRef.$el
      const startPos = field.selectionStart
      // get cursor's position:
      const endPos = field.selectionEnd
      const cursorPos = startPos
      let tmpStr = field.value

      if (this.rewriting) {
        tmpStr = ''
      }

      if (value === null) {
        return
      }

      // insert:
      const templateMessage = tmpStr.substring(0, startPos) + valueString + tmpStr.substring(endPos, tmpStr.length)

      this.$emit('fastInsertSelected', this.inputRefName, templateMessage, () => {
        field.selectionStart = cursorPos + valueString.length
        field.selectionEnd = cursorPos + valueString.length
      })
    },
  },
}
</script>
