export default {
  methods: {
    onSelectedFastInsertSuggestion(inputRefName, value, callback) {
      this.$refs[inputRefName].$el.value = value
      this.$nextTick(() => {
        this.$refs[inputRefName].focus()
        callback()
      })
    },
  },
}
