import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  methods: {
    sendToast({
      title,
      text,
      icon,
      variant,
    }) {
      Vue.$toast({
        component: ToastificationContent,
        props: {
          title: `${title ?? ''}`,
          icon: `${icon ?? 'CheckCircleIcon'}`,
          variant: `${variant ?? 'success'}`,
          text: `${text ?? ''}`,
        },
      })
    },
  },
}
