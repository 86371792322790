import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axiosIns from '@/libs/axios'
import router from '@/router'
import jwtConfig from './jwtConfig'

const JWTService = class JwtService {
  axiosInstance = null

  jwtConfig = { ...jwtConfig }

  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosInstance, jwtOverrideConfig) {
    this.axiosInstance = axiosInstance

    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosInstance.interceptors.request.use(
      config => {
        const accessToken = this.getToken()

        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error),
    )

    this.axiosInstance.interceptors.response.use(
      response => response,
      // eslint-disable-next-line consistent-return
      error => {
        const { config, response } = error
        const originalRequest = config

        if (config.url === this.jwtConfig.loginEndpoint) {
          return Promise.reject(error)
        }

        if (response && response.status === 401) {
          if (config.url === this.jwtConfig.refreshEndpoint) {
            this.tokenIsExpired()
            return Promise.reject(error)
          }
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true

            this.refreshToken().then(responseRefreshData => {
              this.isAlreadyFetchingAccessToken = false
              const responseData = responseRefreshData.data.data
              const userData = responseData.user_data
              this.setToken(responseData.access_token)
              this.setUserData(JSON.stringify(userData))
              this.onAccessTokenFetched(responseData.access_token)
            }).catch(() => {
              this.isAlreadyFetchingAccessToken = false
              this.tokenIsExpired()
            })
          }

          return new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axiosInstance(originalRequest))
            })
          })
        }

        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  tokenIsExpired() {
    this.clearAuthData()
    Vue.$toast({
      component: ToastificationContent,
      props: {
        title: 'Токен устарел',
        icon: 'LogOutIcon',
        variant: 'danger',
        text: 'Ваш токен морально устарел, надо пройти авторизацию снова',
      },
    })
    router.push({ path: 'login' })
  }

  clearAuthData() {
    localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
    localStorage.removeItem(this.jwtConfig.storageUserDataKeyName)
  }

  login(args) {
    return this.axiosInstance({
      method: 'POST',
      url: this.jwtConfig.loginEndpoint,
      data: { email: args.email, password: args.password },
    })
  }

  logout() {
    return this.axiosInstance({
      method: 'POST',
      url: this.jwtConfig.logoutEndpoint,
    })
  }

  refreshToken() {
    return this.axiosInstance({
      method: 'POST',
      url: this.jwtConfig.refreshEndpoint,
    })
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  getUserData() {
    return Vue.ls.get(this.jwtConfig.storageUserDataKeyName)
    // return localStorage.getItem(this.jwtConfig.storageUserDataKeyName)
  }

  setUserData(value) {
    Vue.ls.set(this.jwtConfig.storageUserDataKeyName, value)
    // localStorage.setItem(this.jwtConfig.storageUserDataKeyName, value)
  }
}

Vue.prototype.$jwtService = new JWTService(axiosIns)

export default JWTService
