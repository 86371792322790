import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import { ValidationObserver, ValidationProvider, localize } from 'vee-validate'
import ru from 'vee-validate/dist/locale/ru.json'
// bootstrap.js
// eslint-disable-next-line import/no-extraneous-dependencies
import flatpickr from 'flatpickr'
// eslint-disable-next-line import/extensions,import/no-extraneous-dependencies
import { Russian } from 'flatpickr/dist/l10n/ru.js'

import FastInsert from '@/components/field-helpers/FastInsert.vue'
import CharCounter from '@/components/field-helpers/CharCounter.vue'

import VueFroala from 'vue-froala-wysiwyg'

// Require Froala Editor js file.
// eslint-disable-next-line import/no-extraneous-dependencies
require('froala-editor/js/froala_editor.pkgd.min')
// eslint-disable-next-line import/no-extraneous-dependencies
require('froala-editor/js/plugins.pkgd.min')
// eslint-disable-next-line import/no-extraneous-dependencies
require('froala-editor/js/languages/ru')

Vue.use(VueFroala)

// Form helpers
Vue.component('FastInsert', FastInsert)
Vue.component('CharCounter', CharCounter)

Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
flatpickr.setDefaults({
  locale: Russian,
  enableTime: true,
  dateFormat: 'Y-m-d',
  altFormat: 'd.m.Y',
  altInput: true,
  time_24hr: true,
})
localize('ru', ru)
