export default [
  {
    path: '/metro/',
    name: 'metro',
    component: () => import(/* webpackChunkName: "MetroList" */'@/views/metro/List.vue'),
    meta: {
      pageTitle: 'Метро',
      requiresAuth: true,
      navActiveLink: 'metro',
      breadcrumb: [
        {
          text: 'Метро',
          active: true,
        },
      ],
    },
  },
  {
    path: '/metro/create',
    name: 'metro-create',
    component: () => import(/* webpackChunkName: "MetroCreate" */'@/views/metro/Create.vue'),
    meta: {
      pageTitle: 'Создание',
      requiresAuth: true,
      navActiveLink: 'metro-create',
      breadcrumb: [
        {
          text: 'Метро',
          active: false,
          to: {
            name: 'metro',
          },
        },
        {
          text: 'Создание станции метро',
          active: true,
        },
      ],
    },
  },
  {
    path: '/metro/update/:id',
    name: 'metro-update',
    component: () => import(/* webpackChunkName: "MetroUpdate" */'@/views/metro/Update.vue'),
    meta: {
      pageTitle: 'Редактирование станции метро',
      requiresAuth: true,
      navActiveLink: 'metro-update',
      breadcrumb: [
        {
          text: 'Метро',
          active: false,
          to: {
            name: 'metro',
          },
        },
        {
          text: 'Редактирование станции метро',
          active: true,
        },
      ],
    },
  },
]
