export default [
  {
    path: '/regions',
    name: 'regions',
    component: () => import(/* webpackChunkName: "RegionsList" */'@/views/regions/List.vue'),
    meta: {
      pageTitle: 'Регионы',
      requiresAuth: true,
      navActiveLink: 'regions',
      breadcrumb: [
        {
          text: 'Регионы',
          active: true,
        },
      ],
    },
  },
  {
    path: '/regions/create',
    name: 'regions-create',
    component: () => import(/* webpackChunkName: "RegionsCreate" */'@/views/regions/Create.vue'),
    meta: {
      pageTitle: 'Создание региона',
      requiresAuth: true,
      navActiveLink: 'regions',
      breadcrumb: [
        {
          text: 'Регионы',
          active: false,
          to: {
            name: 'regions',
          },
        },
        {
          text: 'Создание региона',
          active: true,
        },
      ],
    },
  },
  {
    path: '/regions/update/:id',
    name: 'regions-update',
    component: () => import(/* webpackChunkName: "RegionsUpdate" */'@/views/regions/Update.vue'),
    meta: {
      pageTitle: 'Редактирование региона',
      requiresAuth: true,
      navActiveLink: 'regions',
      breadcrumb: [
        {
          text: 'Регионы',
          active: false,
          to: {
            name: 'regions',
          },
        },
        {
          text: 'Редактирование региона',
          active: true,
        },
      ],
    },
  },
]
