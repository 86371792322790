export default [
  {
    path: '/med-journal',
    name: 'med-journal',
    component: () => import(/* webpackChunkName: "MedJournalList" */'@/views/med-journal/List.vue'),
    meta: {
      pageTitle: 'Мед. журнал',
      requiresAuth: true,
      navActiveLink: 'med-journal',
      breadcrumb: [
        {
          text: 'Мед. журнал',
          active: true,
        },
      ],
    },
  },
  {
    path: '/med-journal/create',
    name: 'med-journal-create',
    component: () => import(/* webpackChunkName: "MedJournalCreate" */'@/views/med-journal/Create.vue'),
    meta: {
      pageTitle: 'Создание',
      requiresAuth: true,
      navActiveLink: 'med-journal',
      breadcrumb: [
        {
          text: 'Мед. журнал',
          active: false,
          to: {
            name: 'med-journal',
          },
        },
        {
          text: 'Создание страницы мед. журнала',
          active: true,
        },
      ],
    },
  },
  {
    path: '/med-journal/update/:id',
    name: 'med-journal-update',
    component: () => import(/* webpackChunkName: "MedJournalUpdate" */'@/views/med-journal/Update.vue'),
    meta: {
      pageTitle: 'Редактирование страницы мед журнала',
      requiresAuth: true,
      navActiveLink: 'med-journal',
      breadcrumb: [
        {
          text: 'Мед. журнал',
          active: false,
          to: {
            name: 'med-journal',
          },
        },
        {
          text: 'Редактирование страницы мед. журнала',
          active: true,
        },
      ],
    },
  },
]
