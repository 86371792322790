export default [
  {
    path: '/clinics',
    name: 'clinics',
    component: () => import(/* webpackChunkName: "ClinicsList" */'@/views/clinics/List.vue'),
    meta: {
      pageTitle: 'Клиники',
      requiresAuth: true,
      navActiveLink: 'clinics',
      breadcrumb: [
        {
          text: 'Клиники',
          active: true,
        },
      ],
    },
  },
  {
    path: '/clinics/create',
    name: 'clinics-create',
    component: () => import(/* webpackChunkName: "ClinicsCreate" */'@/views/clinics/Create.vue'),
    meta: {
      pageTitle: 'Создание клиники',
      requiresAuth: true,
      navActiveLink: 'clinics',
      breadcrumb: [
        {
          text: 'Клиники',
          active: false,
          to: {
            name: 'clinics',
          },
        },
        {
          text: 'Создание клиники',
          active: true,
        },
      ],
    },
  },
  {
    path: '/clinics/update/:id',
    name: 'clinics-update',
    component: () => import(/* webpackChunkName: "ClinicsUpdate" */'@/views/clinics/Update.vue'),
    meta: {
      pageTitle: 'Редактирование клиники',
      requiresAuth: true,
      navActiveLink: 'Клиники',
      breadcrumb: [
        {
          text: 'Клиники',
          active: false,
          to: {
            name: 'clinics',
          },
        },
        {
          text: 'Редактирование клиники',
          active: true,
        },
      ],
    },
  },
]
