export default [
  {
    path: '/clinic-types/',
    name: 'clinic-types',
    component: () => import(/* webpackChunkName: "ClinicTypesList" */'@/views/clinic-types/List.vue'),
    meta: {
      pageTitle: 'Типы клиник',
      requiresAuth: true,
      navActiveLink: 'clinic-types',
      breadcrumb: [
        {
          text: 'Типы клиник',
          active: true,
        },
      ],
    },
  },
  {
    path: '/clinic-types/create',
    name: 'clinic-types-create',
    component: () => import(/* webpackChunkName: "ClinicTypesCreate" */'@/views/clinic-types/Create.vue'),
    meta: {
      pageTitle: 'Создание',
      requiresAuth: true,
      navActiveLink: 'clinic-types-create',
      breadcrumb: [
        {
          text: 'Типы клиник',
          active: false,
          to: {
            name: 'clinic-types',
          },
        },
        {
          text: 'Создание типа клиники',
          active: true,
        },
      ],
    },
  },
  {
    path: '/clinic-types/update/:id',
    name: 'clinic-types-update',
    component: () => import(/* webpackChunkName: "ClinicTypesUpdate" */'@/views/clinic-types/Update.vue'),
    meta: {
      pageTitle: 'Редактирование сети клиник',
      requiresAuth: true,
      navActiveLink: 'clinic-types-update',
      breadcrumb: [
        {
          text: 'Типы клиник',
          active: false,
          to: {
            name: 'clinic-types',
          },
        },
        {
          text: 'Редактирование типа клиники',
          active: true,
        },
      ],
    },
  },
]
