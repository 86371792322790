export default [
  {
    path: '/doctors-reviews',
    name: 'doctors-reviews',
    component: () => import(/* webpackChunkName: "DoctorsReviewsList" */'@/views/doctors-reviews/List.vue'),
    meta: {
      pageTitle: 'Отзывы врачей',
      requiresAuth: true,
      navActiveLink: 'doctors-reviews',
      breadcrumb: [
        {
          text: 'Отзывы врачей',
          active: true,
        },
      ],
    },
  },
  {
    path: '/doctors-reviews/create',
    name: 'doctors-reviews-create',
    component: () => import(/* webpackChunkName: "DoctorsReviewsCreate" */'@/views/doctors-reviews/Create.vue'),
    meta: {
      pageTitle: 'Создание отзыва врача',
      requiresAuth: true,
      navActiveLink: 'doctors-reviews',
      breadcrumb: [
        {
          text: 'Отзывы врачей',
          active: false,
          to: {
            name: 'doctors-reviews',
          },
        },
        {
          text: 'Создание отзыва врача',
          active: true,
        },
      ],
    },
  },
  {
    path: '/doctors-reviews/update/:id',
    name: 'doctors-reviews-update',
    component: () => import(/* webpackChunkName: "DoctorsReviewUpdate" */'@/views/doctors-reviews/Update.vue'),
    meta: {
      pageTitle: 'Редактирование отзыва врача',
      requiresAuth: true,
      navActiveLink: 'Редактирование отзыва врача',
      breadcrumb: [
        {
          text: 'Отзывы врачей',
          active: false,
          to: {
            name: 'doctors',
          },
        },
        {
          text: 'Редактирование отзыва врача',
          active: true,
        },
      ],
    },
  },
]
