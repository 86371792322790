export default [
  {
    path: '/doctors',
    name: 'doctors',
    component: () => import(/* webpackChunkName: "DoctorsList" */'@/views/doctors/List.vue'),
    meta: {
      pageTitle: 'Врачи',
      requiresAuth: true,
      navActiveLink: 'doctors',
      breadcrumb: [
        {
          text: 'Врачи',
          active: true,
        },
      ],
    },
  },
  {
    path: '/doctors/create',
    name: 'doctors-create',
    component: () => import(/* webpackChunkName: "DoctorsCreate" */'@/views/doctors/Create.vue'),
    meta: {
      pageTitle: 'Создание врача',
      requiresAuth: true,
      navActiveLink: 'doctors',
      breadcrumb: [
        {
          text: 'Врачи',
          active: false,
          to: {
            name: 'doctors',
          },
        },
        {
          text: 'Создание врача',
          active: true,
        },
      ],
    },
  },
  {
    path: '/doctors/update/:id',
    name: 'doctors-update',
    component: () => import(/* webpackChunkName: "DoctorsUpdate" */'@/views/doctors/Update.vue'),
    meta: {
      pageTitle: 'Редактирование врача',
      requiresAuth: true,
      navActiveLink: 'Врачи',
      breadcrumb: [
        {
          text: 'Врачи',
          active: false,
          to: {
            name: 'doctors',
          },
        },
        {
          text: 'Редактирование врача',
          active: true,
        },
      ],
    },
  },
]
