export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/homepage/Home.vue'),
    meta: {
      pageTitle: 'Главная',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Главная',
          active: true,
        },
      ],
    },
  },
]
