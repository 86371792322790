<template>
  <div>
    <span :class="indicatorClass">{{ value.length }}</span><span v-if="maxChars"> / {{ maxChars }}</span>
  </div>
</template>

<script>
export default {
  props: {
    maxChars: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    warningThreshold: {
      type: String,
      default: '',
    },
  },

  computed: {
    indicatorClass() {
      if (this.maxChars) {
        if (this.value?.length > this.maxChars) {
          return 'text-danger'
        }
      }

      if (this.warningThreshold) {
        if (this.value?.length > this.warningThreshold) {
          return 'text-warning'
        }
      }

      return 'text-success'
    },
  },
}
</script>
