export default [
  {
    path: '/cities',
    name: 'cities',
    component: () => import(/* webpackChunkName: "CitiesList" */'@/views/cities/List.vue'),
    meta: {
      pageTitle: 'Города',
      requiresAuth: true,
      navActiveLink: 'cities',
      breadcrumb: [
        {
          text: 'Города',
          active: true,
        },
      ],
    },
  },
  {
    path: '/cities/create',
    name: 'cities-create',
    component: () => import(/* webpackChunkName: "СitiesCreate" */'@/views/cities/Create.vue'),
    meta: {
      pageTitle: 'Создание города',
      requiresAuth: true,
      navActiveLink: 'cities',
      breadcrumb: [
        {
          text: 'Города',
          active: false,
          to: {
            name: 'cities',
          },
        },
        {
          text: 'Создание города',
          active: true,
        },
      ],
    },
  },
  {
    path: '/cities/update/:id',
    name: 'cities-update',
    component: () => import(/* webpackChunkName: "CitiesUpdate" */'@/views/cities/Update.vue'),
    meta: {
      pageTitle: 'Редактирование города',
      requiresAuth: true,
      navActiveLink: 'cities',
      breadcrumb: [
        {
          text: 'Города',
          active: false,
          to: {
            name: 'cities',
          },
        },
        {
          text: 'Редактирование города',
          active: true,
        },
      ],
    },
  },
]
