export default [
  {
    path: '/clinics-reviews',
    name: 'clinics-reviews',
    component: () => import(/* webpackChunkName: "ClinicsReviewsList" */'@/views/clinics-reviews/List.vue'),
    meta: {
      pageTitle: 'Отзывы клиники',
      requiresAuth: true,
      navActiveLink: 'clinics-reviews',
      breadcrumb: [
        {
          text: 'Отзывы клиники',
          active: true,
        },
      ],
    },
  },
  {
    path: '/clinics-reviews/create',
    name: 'clinics-reviews-create',
    component: () => import(/* webpackChunkName: "ClinicsReviewsCreate" */'@/views/clinics-reviews/Create.vue'),
    meta: {
      pageTitle: 'Создание отзыва врача',
      requiresAuth: true,
      navActiveLink: 'clinics-reviews',
      breadcrumb: [
        {
          text: 'Отзывы врачей',
          active: false,
          to: {
            name: 'clinics-reviews',
          },
        },
        {
          text: 'Создание отзыва врача',
          active: true,
        },
      ],
    },
  },
  {
    path: '/clinics-reviews/update/:id',
    name: 'clinics-reviews-update',
    component: () => import(/* webpackChunkName: "ClinicsReviewUpdate" */'@/views/clinics-reviews/Update.vue'),
    meta: {
      pageTitle: 'Редактирование отзыва врача',
      requiresAuth: true,
      navActiveLink: 'Редактирование отзыва врача',
      breadcrumb: [
        {
          text: 'Отзывы врачей',
          active: false,
          to: {
            name: 'clinics',
          },
        },
        {
          text: 'Редактирование отзыва врача',
          active: true,
        },
      ],
    },
  },
]
